import { defineStore } from 'pinia'

interface State {
	activeModals: string[]
	changeTime: string | null
}

export const useModalStore = defineStore('modal', {
  state: (): State => ({
		activeModals: [],
		changeTime: null
	}),
	getters: {
		getIndex() {
			return (id: string) => {
				return this.activeModals.findIndex((modalId) => modalId === id)
			}
		}
	},
	actions: {
		monitor(data: { id: string, add?: boolean }) {
			if (data.add) {
				this.activeModals.push(data.id)
				this.changeTime = new Date().toJSON()
			} else {
				this.activeModals = this.activeModals.filter((modal) => modal !== data.id)
				this.changeTime = new Date().toJSON()
			}
		}
	}
})
