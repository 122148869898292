<template>
	<div v-if="show" :style="style" @resetTabFlow="resetTabFlow">
		<transition name="transition--fade">
			<div v-if="isVisible" class="modal-backdrop" @mousedown.left="close" />
		</transition>
		<transition name="transition--slide-up">
			<dialog
				v-if="isVisible"
				class="modal"
				:class="{
					'px-4': fullWidth,
				}"
				role="dialog"
				:aria-labelledby="hasHeader ? `modal_${_uid}_header` : false"
				@mousedown.left="close"
				@keyup.esc="close"
			>
				<div
					:class="{
						modal__container: !fullWidth,
						'w-full': fullWidth,
					}"
				>
					<div
						class="flex"
						:class="{
							'max-w-xl': !fullWidth,
							'w-full': fullWidth,
						}"
					>
						<div class="modal__bg max-w-full" @mousedown.stop="">
							<header
								v-if="hasHeader"
								class="modal__header"
								:id="`modal_${_uid}_header`"
							>
								<slot name="header" />
								<button
									v-if="canHide"
									class="modal__header__close-btn btn-icon--only"
									@click="close"
									type="button"
								>
									<i class="icon icon--custom">
										<svg-icon width="16" height="16" name="close-16" />
									</i>
									<span class="sr-only">{{ $t('CLOSE') }}</span>
								</button>
							</header>
							<div
								class="modal__content"
								:class="{ 'modal__content--padded': padded }"
								ref="content"
							>
								<slot />
							</div>
							<footer v-if="hasFooter" class="modal__footer">
								<slot name="footer" />
							</footer>
						</div>
					</div>
				</div>
			</dialog>
		</transition>
	</div>
</template>
<script>
import uniqueId from "lodash/uniqueId";
import {useModalStore} from "~/stores/modal";

export default {
	name: 'modal',
	props: {
		canHide: {
			type: Boolean,
			default: true,
		},
		fullWidth: {
			type: Boolean,
			default: false,
		},
		show: {
			type: Boolean,
			default: false,
		},
		padded: {
			type: Boolean,
			default: true,
		},
	},
	setup() {
		const modalStore = useModalStore()
		return {
			modalStore,
		}
	},
	data() {
		return {
			firstElm: undefined,
			focusedElmBeforeOpen: undefined,
			hasFooter: this.$slots.hasOwnProperty('footer'),
			hasHeader: this.$slots.hasOwnProperty('header'),
			isHidable: false,
			isVisible: false,
			lastElm: undefined,
			uid: uniqueId()
		}
	},
	computed: {
		firstAutofocusElem() {
			return this.focusableElms.find((item) => item.autofocus)
		},
		focusableElms() {
			// Get all tabbable elements with tabindex 0 or higher
			// This does not sort by tabindex so it will not work with tabindex based tab-flow. All tabindex above -1 treated equally
			// this.show -> small hack to keep the computed value reactive when dialog is closed and opened again
			return this.show
				? [].slice
						.call(this.$el.querySelectorAll('input, button, a, select'))
						.filter((item) => item.tabIndex > -1)
				: []
		},
		style() {
			return `position: relative; z-index: ${
				this.modalStore.getIndex(this.uid) + 10000
			};`
		},
	},
	methods: {
		close() {
			if (!this.canHide) {
				return
			}

			const animationTiming = 300 //ms

			// Start hiding animation
			this.isVisible = false

			// Wait for animation to finish, then trigger close event
			setTimeout(() => {
				this.$emit('close')
			}, animationTiming)
		},
		moveFocusToFirst(event) {
			if (!event.shiftKey && event.keyCode == 9) {
				event.preventDefault()
				this.focusableElms[0].focus()
			}
		},
		moveFocusToLast(event) {
			if (event.shiftKey && event.keyCode == 9) {
				event.preventDefault()
				this.focusableElms[this.focusableElms.length - 1].focus()
			}
		},
		resetTabFlow() {
			// If called again, this suggests the order has changed -> remove old event listeners
			if (this.firstElm != null) {
				this.firstElm.removeEventListener('keydown', this.moveFocusToLast)
			}

			if (this.lastElm != null) {
				this.lastElm.removeEventListener('keydown', this.moveFocusToFirst)
			}

			// Assign/re-assign first and last tabbable element
			this.firstElm = this.focusableElms[0]
			this.lastElm = this.focusableElms[this.focusableElms.length - 1]

			// If tab past last element, jump to first
			this.firstElm &&
				this.firstElm.addEventListener('keydown', this.moveFocusToLast)

			// If shift tab past first element, move focus to last
			this.lastElm &&
				this.lastElm.addEventListener('keydown', this.moveFocusToFirst)
		},
		scrollToTop() {
			const content = this.$refs.content

			if (content) {
				content.scrollTop = 0
			}
		},
		showHandler() {
			// Dispatch modal change to modal monitor
			this.modalStore.monitor({
				add: this.show,
				id: this.uid,
			})

			if (this.show) {
				setTimeout(() => {
					this.isVisible = true
				}, 100)
			}
		},
	},
	mounted() {
		// Prevent the modal from being accidentally closed by trigger click
		setTimeout(() => {
			this.isHidable = true
		}, 300)

		this.showHandler()
	},
	beforeDestroy() {
		this.firstElm &&
			this.firstElm.removeEventListener('keydown', this.moveFocusToLast)
		this.lastElm &&
			this.lastElm.removeEventListener('keydown', this.moveFocusToFirst)

		this.$emit('close')

		this.modalStore.monitor({
			add: false,
			id: this.uid,
		})
	},
	watch: {
		isVisible() {
			// Keep focus within dialog when the user is tabbing
			// Need to wait for element to become visible -> setTimeout
			if (this.isVisible) {
				// Set triggering element as element to return focus to
				this.focusedElmBeforeOpen = document.activeElement

				setTimeout(() => {
					this.resetTabFlow()

					// Set focus to first focusable element
					if (this.firstAutofocusElem) {
						this.firstAutofocusElem.focus()
					} else if (this.focusableElms[0]) {
						this.focusableElms[0].focus()
					}
				}, 100)
			} else {
				this.focusedElmBeforeOpen && this.focusedElmBeforeOpen.focus()
			}
		},
		show() {
			this.showHandler()
		},
	},
}
</script>
